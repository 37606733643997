import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { SoftwareHeaderInfo } from "../../components/layout/SoftwareHeaderInfo";
import { Container } from "../../components/layout/Container";
import { StraightContainer } from "../../components/layout/StraightContainer";
import { LeftContainer } from "../../components/layout/LeftContainer";
import { ContainerColour, ContainerSize } from "../../components/layout/DiagonalContainer";
import { CaseStudy } from "../../components/sections/softwareDevelopment/CaseStudy";
import { Gallery } from "../../components/layout/Gallery";
import { SaaSDevelopmentIcon } from "../../components/layout/icons/SaaSDevelopmentIcon";
import { saasDevelopmentData } from "../../content/pageData/SaaSDevelopmentData";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        morpheusMobile: {
            childImageSharp: any;
        }
        morpheus: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        gallery1: {
            childImageSharp: any;
        }
        gallery2: {
            childImageSharp: any;
        }
        gallery3: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const morpheusMobile = data.morpheusMobile.childImageSharp.fluid;
    const morpheus = data.morpheus.childImageSharp.fluid;

    const morpheusSources = [
        morpheusMobile,
        {
            ...morpheus,
            media: `(min-width: 768px)`,
        }
    ]

    const images = [
        data.gallery1.childImageSharp.fluid,
        data.gallery2.childImageSharp.fluid,
        data.gallery3.childImageSharp.fluid,
    ]

    return (
        <Layout
            pageTitle={saasDevelopmentData.metaPageTitle}
            keywords={saasDevelopmentData.metaKeywords}
            description={saasDevelopmentData.metaDescription}
            url={"software-development/saas-development"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.LARGE} straightBottom={true} className="software-header">
                <SoftwareHeaderInfo
                    title={saasDevelopmentData.title}
                    subtitle={saasDevelopmentData.subtitle}
                    icon={<SaaSDevelopmentIcon fill={"#ffffff"} />}
                    image={morpheusSources}
                    imageAlt={"Morpheus CMS Article Editor"}
                    className="centered-header"
                />
            </ParallaxHeader>
            <StraightContainer colour={ContainerColour.ORANGE} size={ContainerSize.SMALL} className="software-service-description">
                <LeftContainer
                    isSoftware={true}
                    title={saasDevelopmentData.description.title}
                    description={saasDevelopmentData.description.description}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Morpheus CMS on mobile"}
                    className="software-sub-service"
                />
            </StraightContainer>
            <Container className="software-container">
                <CaseStudy
                    className="saas-case-study"
                    caseStudy={saasDevelopmentData.caseStudy}
                />
            </Container>
            <Gallery isNative={false} images={images} />
            <Container className="software-container centered-button">
                <Button
                    buttonConfig={{
                        colour: ButtonColour.BLUE,
                        size: ButtonSize.LARGE,
                        ctaLabel: "Back",
                        ctaLink: "/software-development",
                        id: "BackGradient",
                        ariaLabel: "Return to Software Development",
                    }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        morpheusMobile: file(relativePath: { eq: "assets/images/web-development/edge_mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        morpheus: file(relativePath: { eq: "assets/images/saas-development/morpheus.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/saas-development/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery1: file(relativePath: { eq: "assets/images/saas-development/gallery_1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery2: file(relativePath: { eq: "assets/images/saas-development/gallery_2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery3: file(relativePath: { eq: "assets/images/saas-development/gallery_3.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`