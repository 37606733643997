import React from "react";
import { CaseStudyData, SoftwareServiceDescription } from "./WebDevelopmentData";
import { SaaSDevelopmentIcon } from "../../components/layout/icons/SaaSDevelopmentIcon";

export const saasDevelopmentData: SaaSDevPageData = {
    metaPageTitle: "SaaS Development & CMS Case Study - iDream Interactive",
    metaKeywords: "saas development, saas software development, saas development company, software development as a service, software as a service development",
    metaDescription: "There’s no need for complicated software installation and compatibility issues. Let us create your next software as a service solution!",
    title: "SaaS Development",
    subtitle: "No need for complicated software installation and compatibility issues.",
    icon: <SaaSDevelopmentIcon fill={"#ffffff"} />,
    description:
    {
        title: "SaaS solutions are designed to work within any web browser, desktop, or mobile.",
        description: <>
            <p>From eCommerce, customer relationship management (CRM), enterprise resource planning (ERP), project management and more.  We can help create a solution for your needs.</p>
            <p>When designing a SaaS solution, there are many factors that come into play such as the features, API integrations, scalability, configurability and budget of the project.  Behind all of that is the technology that makes it possible.  We are confident that we can select the right solution for you.</p>
            <p>Do you have an idea for a groundbreaking software solution and need someone to build it?  Let’s connect to better understand your needs and how we can help.</p>
        </>,
    },
    caseStudy: {
        deliverables: ["Web Development", "SaaS Development", "UI/UX Design", "Front-end Development", "Back-end Development"],
        client: "Twin Spires",
        title: "Morpheus CMS",
        description: <>
            <p>
                When we redesigned the TwinSpires Edge blog site, it was very clear that their WordPress CMS solution wasn’t working.
                They had performance and stability issues.  We addressed these issues by changing out their front-end/back-end technologies,
                but then they needed a new way to update their content.
            </p>
            <p>
                That’s when we designed a new headless CMS to do the trick.  How did this help?
            </p>
            <p>
                A headless CMS increases performance and simplicity, and improves flexibility by using an API based deployment which ensures
                content is not only future-proofed but also ready to be read, displayed, voiced, projected, or transmitted wherever and however
                users want to consume it.
            </p>
        </>,
    }
}

type SaaSDevPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    subtitle: string,
    icon: JSX.Element,
    description: SoftwareServiceDescription,
    caseStudy: CaseStudyData,
}